"use client";

import { Alert, Button, Card, Group, PasswordInput, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconAlertCircle } from "@tabler/icons-react";
import { zodResolver } from "mantine-form-zod-resolver";
import { useRouter, useSearchParams } from "next/navigation";
import { FC } from "react";
import { LoginArgs, useLogin } from "~/auth/useLogin";
import { LogoType } from "~/lib/components/LogoType";

const LoginPage: FC = () => {
  const { trigger: login, isMutating, error } = useLogin();
  const router = useRouter();
  const searchParams = useSearchParams();
  const redirectTo = searchParams.get("callbackUrl") ?? "/";

  const form = useForm({
    validate: zodResolver(LoginArgs),
    initialValues: {
      email: "",
      password: "",
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    try {
      await login(values);

      setTimeout(() => router.replace(redirectTo), 100);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack w="100%" maw="36em" p="sm" mt={{ base: "-10em", md: 0 }}>
      <LogoType />

      <Card shadow="xs" component="form" onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput label="E-posta" inputMode="email" {...form.getInputProps("email")} />
          <PasswordInput label="Şifre" {...form.getInputProps("password")} />

          {error && (
            <Alert color="red" icon={<IconAlertCircle />}>
              Kullanıcı adı veya şifre hatalı
            </Alert>
          )}

          <Group justify="right">
            <Button type="submit" loading={isMutating} disabled={isMutating}>
              Giriş Yap
            </Button>
          </Group>
        </Stack>
      </Card>
    </Stack>
  );
};

export default LoginPage;
