import { Key } from "swr";
import useSWRMutation, { MutationFetcher, SWRMutationConfiguration } from "swr/mutation";

export const useSWRMutationWithError = <
  Data = unknown,
  Error = unknown,
  SWRMutationKey extends Key = Key,
  ExtraArg = never,
  SWRData = Data
>(
  key: SWRMutationKey,
  fetcher: MutationFetcher<Data, SWRMutationKey, ExtraArg>,
  options?: SWRMutationConfiguration<Data, Error, SWRMutationKey, ExtraArg, SWRData>
) => {
  return useSWRMutation(
    key,
    // @ts-expect-error
    async (key, args) => {
      try {
        const response = await fetcher(key, args);

        // @ts-expect-error
        if (response?.error) {
          // @ts-expect-error
          throw response.error;
        }

        return response;
      } catch (error) {
        // @ts-expect-error
        if (error.message === "NEXT_REDIRECT" || error.message === "NEXT_NOT_FOUND") {
          return error;
        }

        throw error;
      }
    },
    options
  );
};
